import "../scss/custom.scss"

import React from "react"

import Header from "./header"
import Footer from "./footer"

export default function Layout(props) {
    return (
      <div className="container-fluid m-0 p-0 d-flex flex-column justify-content-between" id="layout-container">
         <Header/>
         <div className="mx-auto body">
            {props.children}
         </div>
         <Footer />
      </div>
    )
}
