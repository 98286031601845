import React from "react"

import {Link} from 'gatsby'

import Email from "../svg/email.inline.svg"

export default function Footer(props) {
    return (
        <div className="container-fluid text-center m-0 px-5 pt-4 pb-2 bg-dshade text-lshade">
                <div className="d-flex flex-column justify-content-between text-center align-content-center">
                    <Link to="/" className="h4 text-center text-decoration-none text-brand pb-2">Telafast</Link>
                    <div className="pb-2">
                        <button
                        type="button"
                        className="btn btn-small btn-brand mx-2 position-relative"
                        style={{width: "8rem", height: "3rem"}}
                        >
                            <Link to="/gallery" className=" stretched-link h6 text-decoration-none text-lshade">
                                Gallery
                            </Link>
                        </button>
                        <button
                        type="button"
                        className="btn btn-small btn-brand mx-2 position-relative"
                        style={{width: "8rem", height: "3rem"}}
                        >
                            <Link to="/contact" className=" stretched-link h6 text-decoration-none text-lshade">
                                Contact
                            </Link>
                        </button>
                    </div>
                    <span className="text-left">
                        <Email /> nick@telafast.com
                    </span>
                    <span className="fw-lighter small">
                        Copyright © 2020 Telafast LLC
                    </span>
                </div>
        </div>
    )
}