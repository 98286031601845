import React from "react"
import {Link} from 'gatsby'
import NavButton from "./navbutton"
import Home from '../svg/home.inline.svg'
import Gallery from '../svg/gallery.inline.svg'

export default function Header(props) {
  return (
    <div id="header" className="container-fluid pt-4 text-center">
      <div className="d-none d-lg-inline text-brand float-start px-2">
        <Link to="/" className="text-brand contain hvr-grow">
          <Home style={{width: "3rem"}} />
        </Link>
      </div>
      <Link to="/" className="display-1 text-decoration-none text-brand contain hvr-grow">
        Telafast
      </Link>
      <div className="d-none d-lg-inline text-brand float-end p-2">
        <Link to="/gallery" className=" text-brand contain hvr-grow">
          <Gallery style={{width: "3rem"}} />
        </Link>
      </div>
      <br />
      <h3 className="text-dshade pt-2">
        Grow your small business <i className="text-brand">Telafast</i>
      </h3>
      <h6>
        No hidden costs! Custom Inquiries?
      </h6>
        <button className="btn btn-small btn-brand position-relative"
        >
          <Link to="/quote" className="stretched-link text-decoration-none text-lshade">
            Get Quote
          </Link>
        </button>
      <NavButton />
    </div>
  )
}
